<template lang="pug">

    .home
        center.mt-3
            //img(src="/hy_b.png").logo
        .row.mt-5
            .col-sm-12.col-md-4.offset-md-4.align-self-center
                
                div.mt-3
                    | &nbsp;
                    b-form-group(v-show="showDismissibleAlert")
                    b-alert(variant="danger" :show="showDismissibleAlert" @dismissed="showDismissibleAlert=false")
                        | Invalid password link, please contact our <a href="mailto:contact@ecosystem-manager.co">support</a>.
                
                b-card(v-if="!showDismissibleAlert")
                  
                        b Please choose a password for {{username}}
                        div.mt-3
                        b-form.login-form(@submit.stop.prevent="newPassword(form.password)")
                            
                            b-form-group(id="exampleInputGroup1", label="Password:", label-for="passwordId")
                                b-form-input(id="passwordId", type="password", placeholder="Password", v-model="form.password", :state="!$v.form.password.$invalid")
                                b-form-invalid-feedback(id="input1LiveFeedback") Password must be at least 12 characters long and must contain at least 1 special character and 1 digit
                            b-form-group(id="password", label="Password confirm:", label-for="passwordConfirmId")
                                b-form-input(id="passwordConfirmId", type="password", placeholder="Confirm password", v-model="form.passwordConfirm",:state="!$v.form.passwordConfirm.$invalid")
                                b-form-invalid-feedback(id="input1LiveFeedback") Password must match
                            b-form-group(id="password").mt-5
                                b-button(variant="primary", :disabled="$v.form.$invalid").form-control(id="passwordConfirmId2", type="submit", placeholder="Confirm password", v-model="form.passwordConfirm") Update password
                            .mt-3
                           
    
</template>

<script>


import Vue from 'vue';
import Config from '@/config';
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

function validatePassword(password ) {
  // probably already handled by the required flag
  if(  this.$_.isNull(password) || !this.$_.isString(password) || this.$_.isEmpty(password))
      return false;

  // Minimum of 1 Number
  if(false === /\d/.test(password))
      return false;
  
  if(false === /[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/.test(password))
      return false;

  return true;
}

export default {
    name: 'Password',

    data () {
        return {
            form: {password: "", passwordConfirm: "", pwtoken: ""},
            token: "",
            username: null,
            showDismissibleAlert: false,
            next: "Login",
            client_config: {},
            csfr_response: null
        }
    },
    mixins: [
        validationMixin
    ],
     validations: {
        form: {
            password: {
                required,
                minLength: minLength(12),
                validPassword: validatePassword
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            }
        }
    },
    watch: {
        // call again the method if the route changes
      
    },
    created: function () {
        
    },
    mounted: function() {
        this.getData();
        
        
       
    },
    methods: {

        getData() {
            this.host = Config.config.base_host; 
            return Vue.http.get(this.host+'/csfr_token', this.credentials).then((resp) => {
                this.csfr_response = resp.body;
                this.checkToken(this.$route.params.token);
            });
        },

        checkToken: function() {
            this.form.pwtoken = this.$route.params.pwtoken;
            Vue.http.post(`${Config.config.base_host}/users/token_check`, this.form, {headers: {"X-XSRF-Token": this.csfr_response.access_token}}).then((res) => {
                this.username = res.body.email;
            }, () => {
                this.showDismissibleAlert = true;
            });
        },

        newPassword: function() {
            
            this.form.pwtoken = this.$route.params.pwtoken;
            Vue.http.post(`${Config.config.base_host}/users/password`, this.form, {headers: {"X-XSRF-Token": this.csfr_response.access_token}}).then(() => {
                this.logout();
            }, () => {
                this.showDismissibleAlert = true;
            });

        },

        logout() {
            localStorage.removeItem('id_token');
            localStorage.clear();
            Vue.http.headers.common['Authorization'] = 'JWT none';
            document.location.href = "/";
        },
    },
    components: {

    }
}
</script>

<style scoped lang="scss">

.btn-primary.disabled, .btn-primary:disabled{

   
    color: #aaa;
}
    .logo {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}
</style>
